// Application specific configuration (environment based)
// We expose a function instead of an object to make sure dotenv has been called before the config itself is defined
// Otherwise all import.meta.env.XXX are undefined.
const getRuntimeConfig = () => ({
  application: {
    // Define parameters specific to the application itself (environment-based or not)
    baseUrl: import.meta.env.VITE_APP_BASE_URL,
    // Enable ServiceWorker cache
    enableSW: false // ['production'].includes(import.meta.env.NODE_ENV)
  },
  api: {
    // Define parameters specific to the API (environment-based or not)
    baseUrl: import.meta.env.VITE_API_BASE_URL
  },
  auth: {
    authority: import.meta.env.VITE_AZURE_AUTHORITY_URI,
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
    redirectUri: import.meta.env.VITE_AZURE_REDIRECT_URI,
    disableForceRefreshToken:
      import.meta.env.VITE_AUTH_DISABLE_FORCE_REFRESH_TOKEN === 'true' || false
  },
  dato: {
    // Define parameters specific to the content API (environment-based or not)
    assetUrl: import.meta.env.VITE_DATO_ASSET_BASE_URL,
    baseUrl: import.meta.env.VITE_DATO_BASE_URL,
    previewUrl: import.meta.env.VITE_DATO_PREVIEW_BASE_URL,
    token: import.meta.env.VITE_DATO_TOKEN,
    supportedLocales: import.meta.env.VITE_DATO_SUPPORTED_LOCALES
  },
  build: {
    // Define parameters specific to the build environment (most likely environment-based)
    mode: import.meta.env.VITE_NODE_ENV, // "development" | "production"
    stage: import.meta.env.VITE_STAGE, // "dev" | "staging" | "qa" | "production"
    isomorphic: !import.meta.env.VITE_CSR,
    // crossOriginLoading attribute for webpack (we may want to switch between 'anonymous' and 'use-credentials' based on the stage)
    // https://webpack.js.org/configuration/output/#outputcrossoriginloading
    crossOrigin: import.meta.env.VITE_CROSS_ORIGIN || 'anonymous'
  },
  clientFacing: {
    baseUrl: import.meta.env.VITE_CLIENT_FACING_BASE_URL,
    eventUrl: import.meta.env.VITE_CLIENT_FACING_EVENT_URL
  },
  google: {
    maps: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    locationUrl: import.meta.env.VITE_GOOGLE_MAPS_LOCATION_URL || ''
  },
  kakao: {
    locationUrl: import.meta.env.VITE_KAKAO_MAPS_LOCATION_URL || ''
  },
  digitalSigningMarkets: import.meta.env.VITE_DIGITAL_SIGNING_MARKETS,
  portalUrls: {
    spaces: import.meta.env.VITE_PORTAL_URLS_SPACES,
    visitorMoment: import.meta.env.VITE_PORTAL_URLS_VISITOR_MOMENT,
    handover: import.meta.env.VITE_PORTAL_URLS_HANDOVER
  },
  support: {
    url: import.meta.env.VITE_SERVICE_NOW_URL
  },
  experienceCloud: {
    leadManagement: import.meta.env.VITE_EXPERIENCE_CLOUD,
    polestarCrm: import.meta.env.VITE_EXPERIENCE_CLOUD_POLESTARCRM
  },
  datadog: {
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    sessionReplyRate: import.meta.env.VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE
  },
  stage: import.meta.env.VITE_STAGE,
  appConfig: {
    region: import.meta.env.VITE_APPCONFIG_REGION,
    unAuthRole: import.meta.env.VITE_COGNITO_UNAUTH_ROLE,
    configName: import.meta.env.VITE_FEATUREFLAG_CONFIG_NAME,
    appName: import.meta.env.VITE_FEATUREFLAG_APP_NAME
  },
  awsAccountId: import.meta.env.VITE_TARGET_AWS_ACCOUNT_ID
});

export default getRuntimeConfig;
